<template>
  <div>

  <v-dialog
      v-model="show"
      max-width="1400px"
      persistent
      scrollable
      @input="$refs.form.resetValidation()"
  >
    <v-card>
      <v-card-title>
        Edit Booking
        <v-spacer></v-spacer>
        <div v-if="promotions.length > 0" md="3" style="margin-bottom: -20px">
          <v-autocomplete
              v-if="card_number == null"
              v-model="promotion_code"
              :items="[{ name: 'None', promotion_code: null }, ...promotions]"
              background-color="rgb(206, 168, 0)"
              height="50"
              item-text="name"
              item-value="promotion_code"
              label="Promotions"
              outlined
              @change="verifyBenefit('promotion')"
          >
          </v-autocomplete>
        </div>
      </v-card-title>

      <div class="d-flex justify-space-around headline pa-4">
        <div class="pitch">Facility: {{ facility }}</div>
        <!-- <div class="pitch">Service: {{ service }}</div> -->

        <div class="pitch">
          Price
          {{ total_price | toCurrency }}
          <span
              v-if="discount != null && price != discount.actual_price"
              class="text-decoration-line-through"
          >
            {{ discount.actual_total | toCurrency }}</span
          >
        </div>
      </div>
      <div class="px-4 pt-2" v-if="perCapacity === 1">
        <v-row v-if="order_id" class="mt-0">
          <v-col md="4" sm="4">
            <label for="">Booking date</label>
            <date-field
                v-model="rescheduleForm.date"
                :dayName="true"
                :dense="true"
                :hide-details="true"
                background-color="#fff"
                class-name="q-text-field shadow-0"
                label=""
                outlined
            >
            </date-field>
          </v-col>
          <v-col md="4" sm="4">
            <label class="text-dark-gray font-medium text-xs" for="">Start Time</label>
            <v-select
                v-model="rescheduleForm.start_time"
                :items="times"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Start time is required']"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                hint="Start Time"
                item-text="formatted"
                item-value="time"
                outlined
                @change="changeStartTime"
            >
            </v-select>
          </v-col>
          <v-col md="4" sm="4">
            <label class="text-dark-gray font-medium text-xs" for="">End Time</label>
            <v-select
                v-model="rescheduleForm.end_time"
                :items="endTimes"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'End time is required']"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                dense
                readonly
                disabled
                hide-details="auto"
                item-text="formatted"
                item-value="time"
                outlined
            >
              <template v-slot:item="{ item }">
                <span v-html="item.formatted"></span>
              </template>
              <template v-slot:selection="{ item }">
                <span v-html="item.formatted"></span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </div>

      <div class="pa-4 mt-2">
        <div class="text-blue text-sm font-semibold">Product Details</div>
        <v-row>
          <v-col md="12">
            <v-chip
                v-for="(product, index) in products"
                :key="index"
                :close="true"
                class="ml-2 mb-2"
                color="cyan"
                dark
                label
                @click:close="removeProduct(index)"
            >
              <v-avatar left>
                <view-image :image="product.image_path"></view-image>
              </v-avatar>
              {{ product.name }} x
              {{ product.quantity }}
              -
              {{ product.total_price | toCurrency }}
              <span
                  v-if="product.discount != null"
                  class="text-decoration-line-through pl-1"
              >
                {{ product.discount.actual_total | toCurrency }}</span
              >
            </v-chip>
          </v-col>

          <v-col :md="productCategoryId == -1 ? 2 : 3">
            <label for="">
              Select Category
            </label>
            <v-autocomplete
                v-model="productCategoryId"
                :items="[
                { name: 'All', id: null },
                { name: 'Open Product', id: -1 },
                ...categories,
              ]"
                background-color="#fff"
                item-text="name"
                item-value="id"
                hide-details="auto"
                class="q-autocomplete shadow-0"
                dense
                outlined
                required
                @change="getProducts"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="productCategoryId == -1" md="3">
            <label for="">
              Product name
            </label>
            <v-text-field
                v-model="selectedProduct.title"
                background-color="#fff"
                outlined
                required
                dense
                hide-details="auto"
                class="q-text-field shadow-0"
            />
          </v-col>
          <v-col v-else md="3">
            <label for="">
              Select Product
            </label>
            <v-autocomplete
                v-model="selectedProduct"
                :items="addOnProducts"
                background-color="#fff"
                item-text="name"
                item-value="id"
                outlined
                required
                return-object
                hide-details="auto"
                class="q-autocomplete shadow-0"
                dense
            ></v-autocomplete>
          </v-col>
          <v-col :md="productCategoryId == -1 ? 1 : 2">
            <label for="">
              Quantity
            </label>
            <v-text-field
                v-model="selectedProduct.quantity"
                background-color="#fff"
                min="1"
                outlined
                type="number"
                dense
                hide-details="auto"
                class="q-text-field shadow-0"
            ></v-text-field>
          </v-col>
          <v-col v-if="productCategoryId == -1" md="1">
            <label for="">
              Tax*
            </label>
            <v-select
                v-model="selectedProduct.tax_type_id"
                :items="taxTypes"
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                item-text="text"
                item-value="value"
                outlined
                @change="taxChange()"
                hide-details="auto"
                class="q-autocomplete shadow-0"
                dense
            ></v-select>
          </v-col>
          <v-col v-if="productCategoryId == -1" md="2">
            <label for="">
              Price (Pre Tax)*
            </label>
            <v-text-field
                v-model="selectedProduct.pre_tax_price"
                :prefix="currencyCode"
                background-color="#fff"
                outlined
                required
                rows="2"
                dense
                hide-details="auto"
                class="q-text-field shadow-0"
                @change="calculateTaxVariation($event, 'pre')"
            ></v-text-field>
          </v-col>
          <v-col v-if="productCategoryId == -1" md="2">
            <label for="">
              Price (Post Tax)*
            </label>
            <v-text-field
                v-model="selectedProduct.total_price"
                :prefix="currencyCode"
                background-color="#fff"
                outlined
                required
                rows="2"
                dense
                hide-details="auto"
                class="q-text-field shadow-0"
                @change="calculateTaxVariation($event, 'post')"
            ></v-text-field>
          </v-col>
          <v-col v-else md="2">
            <label>
              Price
            </label>
            <v-text-field
                v-model="selectedProduct.total_price"
                :readonly="productCategoryId != -1"
                :suffix="currencyCode"
                background-color="#fff"
                outlined
                dense
                hide-details="auto"
                class="q-text-field shadow-0"
            ></v-text-field>
          </v-col>
          <v-col md="1" align-self="end">
            <v-btn
                block
                height="40"
                class="ma-2 white--text teal-color"
                @click="addProduct"
            >Add
            </v-btn
            >
          </v-col>
        </v-row>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" class="ma-2 text-capitalize" text
        >Close
        </v-btn>
        <v-btn
            class="white--text blue-color"
            text
            @click="confirmUpdate"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

    <confirm-model
        v-bind="confirmModel"
        @confirm="buyAddOnProduct"
        @close="confirmModel.id = null"
    >
      <template #content v-if="this.order_id && this.confirmModel.type === 'update' && typeof confirmModel.data.notify_customers !== 'undefined'">
        <v-row>
          <v-col cols="6">
            <v-switch
                class="mx-0 my-0"
                v-model="notify_customers"
                label="Notify Customers"
                dense
                hide-details="auto"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
    </confirm-model>
  </div>
</template>

<script>
// import moment from "moment";
import moment from "moment/moment";

export default {
  computed: {
    promotions() {
      return this.$store.getters.getPromotions.data;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },
  props: {
    show: {type: Boolean, default: false},
    order_id: {type: Number, default: null},
    facilityId: {type: Number, default: null},
    facility: {type: String, default: null},
    customer_id: {type: Number, default: null},
    mobile: {type: String, default: null},
    venue_service_id: {type: Number, default: null},
    start_time: {type: String, default: null},
    end_time: {type: String, default: null},
    date: {type: String, default: null},
    bookingDetails: {type: Object, default: null},
    perCapacity: { type: Number, default: 0 }
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        this.discount = {};
        this.products = [];
        this.price = 0;
        this.total_price = 0;
        this.card_number = null;
        this.promotion_code = null;
        this.selectedProduct = {};
        this.getRescheduleDetails()
      },
    },
    facilityId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getAddOnProductsCategories();
        }
      },
    },
    customer_id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getAddOnProductsCategories();
          this.searchMember();
        }
      },
    },
    'rescheduleForm.date': {
      handler() {
        this.getRescheduleDetails(true);
      },
    },
  },
  data() {
    return {
      categories: [],
      card_number: null,
      discount: {},
      products: [],
      addOnProducts: [],
      price: 0,
      total_price: 0,
      selectedProduct: {},
      promotion_code: null,
      productCategoryId: null,
      rescheduleForm: {
        date: this.bookingDetails.date,
        start_time: this.bookingDetails.start_time,
        end_time: this.bookingDetails.end_time,
      },
      times: [],
      minBookingTime: 60,
      endTimes: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      notify_customers:false
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.discount = {};
      this.products = [];
      this.price = 0;
      this.total_price = 0;
      this.card_number = null;
      this.promotion_code = null;
      this.selectedProduct = {};
    },
    getAddOnProductsCategories() {
      if (this.facilityId != null) {
        this.$http
            .get(
                `venues/facilities/bookings/add-on-products?facility_id=${this.facilityId}&date=${this.date}&start_time=${this.start_time}&end_time=${this.end_time}`
            )
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.categories = response.data.data.categories;
                // this.addOnProducts = response.data.data;
                this.getProducts();
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      }
    },
    getRescheduleDetails(timeChanged = false) {
      if (!this.bookingDetails.facility_booking_id) {
        return;
      }
      this.showLoader("Loading");
      let url = `${this.rescheduleForm.date ? "&date=" + this.rescheduleForm.date : ""}`;
      url += `${this.rescheduleForm.start_time ? "&start_time=" + this.rescheduleForm.start_time : ""}`;
      url += `${this.rescheduleForm.end_time ? "&end_time=" + this.rescheduleForm.end_time : ""}`;

      this.$http.get(`venues/facilities/bookings/reschedule-capacity/${this.bookingDetails.facility_booking_id}?venue_service_id=${this.venue_service_id}${url}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.minBookingTime = data.min_booking_time;
              this.endTimes = this.endTimeSlots(data.start_time, data.times);
              data.times.pop()
              this.times = data.times;
              if (!timeChanged) {
                this.rescheduleForm = {
                  start_time: data.start_time,
                  end_time: data.end_time,
                  date: data.date,
                  facility_id: data.facility_id,
                };
              }
              this.hideLoader();
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },

    endTimeSlots(start_time, et) {
      if (et) {
        const timeSlotsFs = [];
        const timeSlotsSc = [];
        et.forEach((ob) => {
          timeSlotsFs.push({
            formatted: moment(ob.time, "HH:mm:ss").format('hh:mm a'),
            time: ob.time,
          });
        })

        return timeSlotsFs.concat(timeSlotsSc);
      }
    },
    getProducts() {
      this.selectedProduct = {};
      this.addOnProducts = [];
      if (this.productCategoryId == -1) {
        return;
      }

      if (this.productCategoryId != null) {
        this.addOnProducts = this.categories.find(
            (item) => item.id == this.productCategoryId
        ).products;
      }
      if (this.productCategoryId == null) {
        this.addOnProducts = [];
        this.categories.forEach((category) => {
          category.products.forEach((product) => {
            product.category_id = category.id;
            product.product_price = product.price;
            this.addOnProducts.push(product);
          });
        });
      }
      this.$forceUpdate();
    },

    removeProduct(index) {
      let data = this.products[index];
      if (this.products[index].inventory_enable) {
        let products = this.categories.find(
            (item) => item.id == data.category_id
        ).products;

        if (products) {
          products.forEach((el) => {
            if (el.id == data.product_id) {
              el.sales -= data.quantity;
            }
          });
        }
      }

      this.products.splice(index, 1);

      this.price -= data.price;
      this.total_price -= data.price + data.tax * data.quantity;
      if (this.promotion_code != null) {
        this.verifyBenefit("promotion");
      }
      if (this.card_number != null) {
        this.verifyBenefit("membership");
      }
      this.$forceUpdate();
    },
    confirmUpdate() {
        this.confirmModel = {
          id: this.order_id,
          title: `Do you want update this booking?`,
          description: `This will update the booking. By clicking <b>Yes</b> you can confirm update operation`,
          type: "update",
          data:{
            id:this.order_id,
            notify_customers:false
          }
        }
    },
    addProduct() {
      if (this.selectedProduct.quantity <= 0) {
        this.showError("Increase the quantity");
        return;
      }
      let pIndex = this.products.findIndex(
          (item) => item.product_id == this.selectedProduct.id
      );

      let quantity =
          this.selectedProduct.quantity && this.selectedProduct.quantity > 0
              ? this.selectedProduct.quantity
              : 1;

      if (this.selectedProduct.inventory_enable) {
        let sales = this.selectedProduct.sales ? this.selectedProduct.sales : 0;
        let totalQty = sales + parseFloat(quantity);
        parseFloat(this.selectedProduct.sales) + parseFloat(quantity) >=
        parseFloat(this.selectedProduct.actual_qty);
        if (parseFloat(this.selectedProduct.actual_qty) < totalQty) {
          if (
              this.selectedProduct.actual_qty - this.selectedProduct.sales ==
              0
          ) {
            this.showError("Product not available!");
          } else {
            this.showError(
                `Only ${this.selectedProduct.actual_qty -
                this.selectedProduct.sales} product available!`
            );
          }
          return;
        }
      }

      if (this.selectedProduct.inventory_enable) {
        this.selectedProduct.sales += parseFloat(quantity);
      }

      if (
          this.selectedProduct.id == null &&
          this.selectedProduct.title == null
      ) {
        this.showError("Please select product");
        return;
      }

      if (!this.selectedProduct.price) {
        this.showError("Please enter price.");
        return;
      }

      if (this.selectedProduct.title != null) {
        if (this.selectedProduct.tax_type_id == null) {
          this.showError("Please select tax");
          return;
        }
        if (this.selectedProduct.price == null) {
          this.showError("Please add price");
          return;
        }

        this.selectedProduct.id = null;
        this.selectedProduct.name = this.selectedProduct.title;
        if (this.selectedProduct.tax_type_id == 1) {
          this.selectedProduct.tax_amount =
              this.selectedProduct.total_price - this.selectedProduct.price;
        }
      }

      let price = parseFloat(this.selectedProduct.price) * parseFloat(quantity);

      if (pIndex == -1) {
        var obj = {
          product_id: this.selectedProduct.id ? this.selectedProduct.id : 0,
          price: price,
          product_price: this.selectedProduct.product_price,
          name: this.selectedProduct.name,
          tax: this.selectedProduct.tax_amount
              ? this.selectedProduct.tax_amount
              : 0,
          quantity: parseFloat(quantity),
          product_type_id: this.selectedProduct.product_type_id,
          inventory_enable: this.selectedProduct.inventory_enable,
          venue_service_id: this.venue_service_id,
          category_id: this.selectedProduct.category_id,
          total_price:
              price +
              parseFloat(
                  this.selectedProduct.tax_amount
                      ? this.selectedProduct.tax_amount
                      : 0
              ) *
              quantity,
        };
        this.products.push(obj);
      } else {
        let newQuantity =
            parseFloat(this.products[pIndex].quantity) + parseFloat(quantity);
        this.products[pIndex].quantity = newQuantity;
        this.products[pIndex].product_price = this.selectedProduct.price;
        this.products[pIndex].price = this.selectedProduct.price * newQuantity;
        this.products[pIndex].total_price =
            this.products[pIndex].price + this.products[pIndex].tax * newQuantity;
      }

      this.price += price;
      this.total_price = this.products.reduce(
          (a, b) => a + parseFloat(b.total_price),
          0
      );

      this.selectedProduct = {};
      if (this.promotion_code != null) {
        this.verifyBenefit("promotion");
      }
      if (this.card_number != null) {
        this.verifyBenefit("membership");
      }
      this.$forceUpdate();
    },

    clearBenefit() {
      if (this.discount) {
        this.price = this.discount.actual_price;
        this.total_price = this.discount.actual_total;
        this.products.forEach((product, index) => {
          if (product.discount) {
            this.products[index].price = product.discount.actual_price;
            this.products[index].total_price = product.discount.actual_total;
          }
          this.products[index].discount = null;
        });
        setTimeout(() => {
          this.discount = null;
          this.$forceUpdate();
        });
      }
    },

    verifyBenefit(type) {
      if (this.products.length == 0) {
        this.showError("Please add atleast one product");
        return;
      }
      let data = {
        products: [],
      };
      if (type == "promotion") {
        data.promotion_code = this.promotion_code;
        if (data.promotion_code == null) {
          this.clearBenefit();
          return;
        }
      } else {
        data.card_number = this.card_number;
      }
      if (this.mobile) {
        data.mobile = this.mobile;
      }
      if (this.discount) {
        data.products = [];
        this.products.forEach((product) => {
          if (!product.category_id) {
            product.product_type_id = 6;
          }
          let pdata = product;
          if (product.discount) {
            pdata.price = product.discount.actual_price;
            delete pdata.discount;
          } else {
            if (product.product_price) {
              pdata.price = product.product_price;
            }
          }
          data.products.push(pdata);
        });
      } else {
        data.products = this.products;
        data.products.forEach((element) => {
          if (element.product_price) {
            element.price = element.product_price;
          }
        });
      }

      let url = "venues/benefits/verify";
      this.$http
          .post(url, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.discount = data.discount;
              this.price = data.price;
              this.products = data.products;
              this.total_price = data.total;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    searchMember() {
      this.isSearchLoading = true;
      let query = "";
      query = `field=id&search=${this.customer_id}`;
      this.$http
          .get(`venues/memberships/members/filters?${query}`)
          .then((response) => {
            if (response.status == 200) {
              let data = response.data.data;
              if (data.length > 0) {
                this.card_number = data[0].card_number;
                this.$forceUpdate();
                if (this.products.length > 0) {
                  this.verifyBenefit("membership");
                }
              }
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.clearCardAndBenefits();
          });
    },

    taxChange() {
      if (this.selectedProduct.price) {
        this.calculateTaxVariation(this.selectedProduct.price, "pre");
      } else if (this.selectedProduct.total_price) {
        this.calculateTaxVariation(this.selectedProduct.total_price, "post");
      }
    },

    calculateTaxVariation(amount, type) {
      let taxTypeId = this.selectedProduct.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      this.selectedProduct.price = priceData.price;
      this.selectedProduct.pre_tax_price = priceData.price.toFixed(2);
      this.selectedProduct.total_price = priceData.total_price;
      this.$forceUpdate();
    },
    buyAddOnProduct() {
      this.confirmModel.id = null;
      const rescheduleCondition =
          this.bookingDetails.start_time == this.rescheduleForm.start_time
          && this.bookingDetails.end_time == this.rescheduleForm.end_time
          && this.bookingDetails.date == this.rescheduleForm.date;

      if (this.products.length == 0 && rescheduleCondition) {
        this.showError("Please make some changes.");
        return;
      }

      let data = {
        order_id: this.order_id,
        products: this.products,
        venue_service_id: this.venue_service_id,
      };

      if (this.promotion_code) {
        data.promotion_code = this.promotion_code;
      }
      if (this.card_number) {
        data.card_number = this.card_number;
      }
      let rescheduleData = {};
      if (this.bookingDetails.start_time !== this.rescheduleForm.start_time || this.bookingDetails.end_time !== this.rescheduleForm.end_time || this.bookingDetails.date !== this.rescheduleForm.date) {
        rescheduleData = {
          date: this.rescheduleForm.date,
          start_time: this.rescheduleForm.start_time,
          end_time: this.rescheduleForm.end_time,
          facility_booking_id: this.bookingDetails.facility_booking_id,
          order_id: this.order_id,
        }
      }
      data.notify_customers = this.notify_customers;
      data = {...data, ...rescheduleData};
      this.showLoader("Updating...")
      this.$http
          .post(`venues/facilities/bookings/save-capacity-booking`, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.$emit("confirmPurchaseAddOnProduct", response.data.data.id);
              this.close();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(()=>{
            this.hideLoader()
          });
    },

    changeStartTime(){
      const startTime = moment(this.rescheduleForm.start_time, "HH:mm:ss");
      let endTime = startTime.clone().add(this.minBookingTime, "minutes");

      const formattedStartTime = startTime.format("HH:mm:ss");
      const formattedEndTime = endTime.format("HH:mm:ss");

      if (moment(formattedEndTime, "HH:mm:ss").isBefore(moment(formattedStartTime, "HH:mm:ss")))
      {
        endTime = moment("23:59:59", "HH:mm:ss");
      }

      this.rescheduleForm.end_time = endTime.format("HH:mm:ss");
    },
  },
};
</script>

<style></style>
