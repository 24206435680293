<template>
  <v-dialog v-model="dialog" width="60%" scrollable @input="close" persistent>
    <v-card>
      <div class="d-flex justify-space-between align-center border-bottom pa-4">
        <div class="title-text">Booking Details</div>
        <div class="d-flex justify-end align-center gap-x-2">
          <div class="d-flex flex-column align-end">
            <div class="text-xs" v-if="bookingDetails.sales_team_id">
              Sales Person: <b style="color:#112A46">{{ bookingDetails.sales_person }}</b>
            </div>
            <div class="text-xs">
              Timestamp: <b style="color:#112A46">{{ bookingDetails.created_at | timeStamp }}</b>
            </div>
          </div>
          <div class="pointer" @click="close">
            <ModalCloseIcon/>
          </div>
        </div>
      </div>
      <v-card-text>
        <v-row>
          <v-col md="12">
            <div class="bg-white bordered rounded-lg mt-2 relative">
              <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-3">
                <div class="title-text">{{ bookingDetails.is_trainer == 1 ? "Trainer" : "Customer" }}
                  Details
                </div>
                <div class="d-flex justify-end align-start"
                     v-if="
              bookingDetails.is_trainer == 1 ||
              (bookingDetails.group_customers &&
                bookingDetails.group_customers.length > 1)
            "
                >
                  <v-btn @click="participantDialog = true" small text
                  >Show Participants
                  </v-btn
                  >
                </div>
              </div>
              <div class="pa-4">
                <v-row>
                  <v-col md="4" lg="3" sm="6" xl="3"
                         style="max-width: 350px; overflow: hidden; text-overflow: ellipsis;">
                    <div class="details_subtitle  pt-2">Name</div>
                    <span class="text_ellipse text-neon text-base pointer font-bold"
                          @click="showUserModel(bookingDetails.customer_id)">{{ bookingDetails.name }}</span>
                  </v-col>

                  <v-col md="4" lg="3" sm="6" xl="3">
                    <div class="details_subtitle pt-2">Email</div>
                    <div class="details_data">
                      {{ bookingDetails.email || "NA" }}
                    </div>
                  </v-col>

                  <v-col md="4" lg="3" sm="6" xl="3">
                    <div class="details_subtitle pt-2">Mobile</div>
                    <div class="details_data">
                      {{ bookingDetails.mobile }}
                    </div>
                  </v-col>

                  <v-col md="4" lg="3" sm="6" xl="3" v-if="field.gender.is_visible">

                      <label for="">Gender</label>
                      <v-select
                          v-model="bookingDetails.gender"
                          :items="['Male', 'Female']"
                          label=""
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          required
                          dense
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                      ></v-select>

                  </v-col>
                  <v-col
                      md="4"
                      xl="3"
                      sm="6"
                      lg="3"

                      v-if="field.nationality.is_visible"
                  >

                      <label for="">Nationality</label>
                      <v-autocomplete
                          v-model="bookingDetails.country_id"
                          :items="countries"
                          label=""
                          item-value="id"
                          item-text="name"
                          outlined
                          background-color="#fff"
                          dense
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                      ></v-autocomplete>

                  </v-col>
                  <v-col md="4" lg="3" sm="6" xl="3" class="" v-if="field.dob.is_visible">


                      <label for="">Date Of Birth</label>
                      <date-of-birth
                        :dense="true"
                        label=""
                        class-name="q-text-field shadow-0 add-on"
                        hide-details="auto"
                        v-model="bookingDetails.dob">
                      </date-of-birth
                      >

                  </v-col>
                  <v-col
                      md="4"
                      lg="3"
                      xl="3"
                      class="mt-1"
                      v-if="field.idProof.is_visible"
                  >

                    <label for="">ID Type{{ field.idProof.is_required ? '*' : '' }}</label>
                      <v-select
                          :hint="`ID Type${field.idProof.is_required ? '*' : ''}`"
                          label=""
                          :rules="idTypeRule"
                          outlined
                          dense
                          :menu-props="{ bottom: true, offsetY: true }"
                          item-value="id"
                          item-text="name"
                          :items="idProofTypes"
                          v-model="bookingDetails.id_proof_type_id"
                          background-color="#fff"
                          hide-details="auto"
                          class="q-autocomplete shadow-0"
                      ></v-select>

                  </v-col>
                  <v-col
                      md="4"
                      sm="6"
                      xl="3"
                      lg="3"
                      class="mt-1"
                      v-if="field.idProof.is_visible"
                  >
                    <label for="">ID Number{{
                        field.idProof.is_required ? '*' : ''
                      }}</label>
                      <v-text-field
                          :hint="`ID Number${field.idProof.is_required ? '*' : ''}`"
                          label=""
                          :rules="idTypeRule"
                          outlined
                          v-model="bookingDetails.id_proof_number"
                          background-color="#fff"
                          dense
                          hide-details="auto"
                          class="q-text-field shadow-0"
                      ></v-text-field>

                  </v-col>

                  <v-col
                      md="4"
                      xl="3"
                      sm="6"
                      lg="3"
                      class="mt-1"
                      v-if="field.idProof.is_visible"
                  >

                      <v-file-input
                          dense
                          v-model="bookingDetails.id_proof"
                          :placeholder="`${
                        bookingDetails.id_proof ? 'Change' : 'Select'
                      }${field.idProof.is_required ? '*' : ''}`"
                          :label="`${
                        bookingDetails.id_proof ? 'Download' : 'ID Proof'
                      } ${field.idProof.is_required ? '*' : ''}`"
                          :rules="idProofRule"
                          prepend-icon=""
                          background-color="#fff"
                          outlined
                          hide-details="auto"
                          class="q-text-field shadow-0"
                          clearable
                      >
                        <template v-slot:prepend-inner>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                  color="cyan"
                                  v-if="bookingDetails.id_proof"
                                  @click="openFile(bookingDetails.id_proof_url)"
                                  v-on="on"
                              >
                                mdi-download-box
                              </v-icon>
                              <v-icon v-else v-on="on">
                                mdi-card-account-details
                              </v-icon>
                            </template>
                            <span v-if="bookingDetails.id_proof"
                            >Download uploaded file</span
                            >
                            <span v-else>Upload ID Proof</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                              v-if="index == 0"
                              color="cyan accent-4"
                              dark
                              label
                              small
                          >
                          <span
                              v-if="text"
                              style="width: 38px"
                              class="text-truncate"
                          >{{ text }}</span
                          >
                            <span
                                v-else
                                style="width: 38px"
                                class="text-truncate"
                            >{{
                                bookingDetails.id_proof &&
                                bookingDetails.id_proof_name
                                    ? bookingDetails.id_proof_name
                                    : null
                              }}</span
                            >
                          </v-chip>
                        </template>
                      </v-file-input>

                  </v-col>

                  <v-col
                      md="3"
                      lg="3"
                      sm="6"
                      class="mt-1"
                      v-if="field.terms_conditions.is_visible"
                  >
                    <label for="">
                      {{
                        bookingDetails.terms_conditions
                            ? 'Download'
                            : 'Terms & Conditions'
                      }} {{ field.idProof.is_required ? '*' : '' }}
                    </label>
                      <v-file-input
                          dense
                          v-model="bookingDetails.terms_conditions"
                          :placeholder="`${
                        bookingDetails.terms_conditions ? 'Change' : 'Select'
                      }${field.idProof.is_required ? '*' : ''}`"
                          label=""
                          :rules="idProofRule"
                          prepend-icon=""
                          background-color="#fff"
                          hide-details="auto"
                          class="q-text-field shadow-0"
                          outlined
                      >
                        <template v-slot:prepend-inner>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                  color="cyan"
                                  v-if="bookingDetails.terms_conditions"
                                  @click="
                                openFile(bookingDetails.terms_conditions_url)
                              "
                                  v-on="on"
                              >
                                mdi-download-box
                              </v-icon>
                              <v-icon v-else v-on="on">
                                mdi-card-account-details
                              </v-icon>
                            </template>
                            <span v-if="bookingDetails.terms_conditions"
                            >Download uploaded file</span
                            >
                            <span v-else>Upload Terms and Conditions</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                              v-if="index == 0"
                              color="cyan accent-4"
                              dark
                              label
                              small
                          >
                          <span
                              v-if="text"
                              style="width: 38px"
                              class="text-truncate"
                          >{{ text }}</span
                          >
                            <span
                                v-else
                                style="width: 38px"
                                class="text-truncate"
                            >{{
                                bookingDetails.terms_conditions &&
                                bookingDetails.terms_conditions
                                    ? bookingDetails.terms_conditions
                                    : null
                              }}</span
                            >
                          </v-chip>
                        </template>
                      </v-file-input>

                  </v-col>
                </v-row>
              </div>
              <div
                  class="pa-0"
              >
                <v-btn
                    absolute
                    bottom
                    right
                    style="max-width: 100px"
                    class="text-truncate text-capitalize text-neon shadow-0 bordered"
                    color="#EDF7F7"
                    dark
                    @click="saveCustomer()"
                >Update
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>

        <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-3"
             v-if="uploadedAttachments.length > 0">
          <div class="title-text">Uploaded Documents</div>
        </div>
        <v-row v-if="uploadedAttachments.length > 0">
          <v-col md="12">
            <v-card outlined class="pa-4 mt-2">
              <template v-for="doc in uploadedAttachments">
                <v-chip
                    :key="doc.id"
                    v-if="doc.document_path"
                    color="grey accent-4 mr-2 mb-2"
                    dark
                    label
                    @click="openFile(doc.document_path)"
                    small
                >
                  {{ doc.document_name }}
                </v-chip>
              </template>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <div class="bg-white bordered rounded-lg pb-2 mt-2">
              <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-3">
                <div class="title-text">Payment Details</div>
              </div>
              <v-row
                  v-for="(payment, i) in bookingDetails.payments"
                  :key="i"
                  class="pa-4"
              >
                <v-col md="3">
                  <div class="details_subtitle">Payment Type</div>
                  <div class="details_data">
                    {{
                      paymentMethods(payment.payment_method_id)
                          ? paymentMethods(payment.payment_method_id)
                          : payment.payment_method_name
                    }}
                  </div>
                </v-col>
                <v-col md="3">
                  <div class="details_subtitle">Amount</div>
                  <div class="details_data">
                    {{ payment.total }}
                  </div>
                </v-col>
                <v-col md="3">
                  <div
                      class="details_subtitle pt-2"
                      v-if="payment.card_holder_name"
                  >
                    Card Holder Name
                  </div>
                  <div class="details_data" v-if="payment.card_holder_name">
                    {{ payment.card_holder_name || "NA" }}
                  </div>
                </v-col>
                <v-col md="3">
                  <div class="details_subtitle pt-2" v-if="payment.card_number">
                    Card Holder Number
                  </div>
                  <div class="details_data" v-if="payment.card_number">
                    {{ payment.card_number || "NA" }}
                  </div>
                </v-col>
                <v-col
                    md="3"
                    v-if="
                    payment.card_expiry_date ||
                    payment.card_type ||
                    payment.payment_code
                  "
                >
                </v-col>
                <v-col md="3" v-if="payment.card_expiry_date">
                  <div class="details_subtitle pt-0">Expiry</div>
                  <div class="details_data" v-if="payment.card_expiry_date">
                    {{ payment.card_expiry_date || "NA" }}
                  </div>
                </v-col>
                <v-col md="3" v-if="payment.card_type">
                  <div class="details_subtitle pt-0">Card Type</div>
                  <div class="details_data" v-if="payment.card_type">
                    {{ payment.card_type || "NA" }}
                  </div>
                </v-col>
                <v-col md="3" v-if="payment.payment_code">
                  <div class="details_subtitle pt-0">Auth code</div>
                  <div class="details_data" v-if="payment.payment_code">
                    {{ payment.payment_code || "NA" }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <div class="bg-white bordered rounded-lg pb-4 mt-2">
              <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-3">
                <div class="title-text">Booking Details</div>
                <div class="d-flex justify-end align-start gap-x-2">
                  <div>
                    Date: <b style="color:#112A46">{{ bookingDetails.date | dayDateFormat }}</b>
                  </div>
                  <div>
                    Time: <b style="color:#112A46">{{ bookingDetails.start_time | timeFormat }} -
                    {{ bookingDetails.end_time | timeFormat }}</b>
                  </div>
                </div>
              </div>
              <!--              inside row here-->
              <v-col md="12">
                <div
                    class="bg-white rounded-lg pb-0 mt-3"
                >
                  <div style="width: 100%" class="table-responsive products-table">
                    <table class="table text-center table-td-bottom-bordered ">
                      <thead>
                      <tr class="text-white tr-neon tr-rounded text-center font-bold black-text">
                        <th class="text-left">Items</th>
                        <th class="text-center">Qty</th>
                        <th class="text-center">Rate</th>
                        <th class="text-center">Tax</th>
                        <th class="text-center">Discount</th>
                        <th class="text-center">Total</th>
                      </tr>
                      </thead>
                      <tbody class="products-table-body">
                      <tr
                          v-for="(product, i) in bookingDetails.products"
                          :key="i"
                      >
                        <td class="pt-2">
                          <div class="d-flex align-center gap-x-1">
                            <view-image :image="product.image" :contain="true" :height="40" :width="40"
                                        :max-height="'40'" :max-width="'40'"></view-image>

                            <div class="text-left">
                              <p class="mb-0">{{ product.name }}</p>
                              <p class="mb-0 text-xs text-neon">({{ product.invoice_seq_no }})</p>
                            </div>
                          </div>
                        </td>
                        <td class="pt-2">{{
                            product.quantity &&
                            typeof product.quantity == "number"
                                ? product.quantity.toFixed(2)
                                : product.quantity
                          }}
                        </td>
                        <td v-if="product.discount" class="pt-2">
                          {{ product.product_price }}
                        </td>
                        <td v-else class="pt-2">
                          {{
                            product.product_price
                                ? product.product_price
                                : product.price
                          }}
                        </td>
                        <td class="pt-2">
                          {{
                            (product.discount
                                ? product.discount.actual_tax
                                : product.tax) | toCurrency
                          }}
                        </td>
                        <td v-if="product.discount" class="pt-2">
                          <div class="text-center align-center">
                            <p class="mb-0 text-sm">
                              {{
                                Math.abs(
                                    product.discount.actual_price - product.price
                                ) | toCurrency
                              }}
                              +
                              {{
                                Math.abs(
                                    product.discount.actual_tax - product.tax
                                ) | toCurrency
                              }}
                              =
                              {{
                                Math.abs(
                                    product.discount.actual_total - product.total
                                ) | toCurrency
                              }}</p>
                            <p class="mb-0 text-xs text-neon">Discount - {{
                                bookingDetails.promotion_code ? bookingDetails.promotion_code : bookingDetails.card_number ? bookingDetails.card_number : 'Special'
                              }}</p>
                          </div>
                        </td>
                        <td v-else class="pt-2"> -</td>
                        <td class="pt-2">
                          {{ currencyCode }}
                          {{ product.total }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <v-divider></v-divider>
                  <v-row class="mt-4">
                    <v-spacer></v-spacer>
                    <v-col lg="4" md="6" sm="12" cols="12">
                      <div class="d-flex justify-space-between subtitle-2">
                        <div class="ml-4">Subtotal</div>
                        <div class="mr-4">
                          {{ Math.abs(bookingDetails.price) | toCurrency }}
                        </div>
                      </div>
                      <div class="d-flex justify-space-between subtitle-2">
                        <div class="ml-4">Tax</div>
                        <div class="mr-4">
                          {{ Math.abs(bookingDetails.tax) | toCurrency }}
                        </div>
                      </div>
                      <div
                          class="d-flex justify-space-between font-weight-bold subtitle-1 black--text"
                      >
                        <div class="ml-4">Total</div>

                        <div class="mr-4">
                      <span
                          class="text-decoration-line-through pt-1"
                          v-if="bookingDetails.discount"
                      >
                        {{ bookingDetails.actual_total | toCurrency }}
                      </span>
                          {{ Math.abs(bookingDetails.total) | toCurrency }}
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </div>
          </v-col>

        </v-row>
        <div class="details_title mt-4" v-if="bookingDetails.order_notes">
          Booking Notes
        </div>
        <v-card outlined v-if="bookingDetails.order_notes">
          <v-card-text>
            {{ bookingDetails.order_notes }}
          </v-card-text>
        </v-card>
        <repeat-bookingPayment
            v-if="repeatId"
            :repeatId="repeatId"
            :openProduct="bookingWithOpenProduct"
            @repeatDatesForPayment="repeatDatesForPaymentChange"
        ></repeat-bookingPayment>
      </v-card-text>
      <v-card-actions>

        <v-menu
            top
            nudge-top="0"
            content-class="q-menu"
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="text_capitalize mr-4"
                elevation="0"
                outlined
                :ripple="false"
                style="min-width: 36px !important; "
                v-bind="attrs"
                v-on="on"
            >
              <DotsIcon/>
              More options
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="bookingDetails.is_trainer == 0" @click="showAddOnProduct = true">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Edit">
                <template #icon>
                  <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item v-if="bookingDetails.is_booking_approved == 0" @click="approveBooking">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Approve">
                <template #icon>
                  <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item v-if="bookingDetails.alert_notes != null &&
            bookingDetails.alert_notes != ''" @click="openAlert(bookingDetails.customer_id)">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Alert Notes">
                <template #icon>
                  <AlertIcon height="16" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item
                v-if="
            perCapacity != 1 &&
            bookingDetails.is_trainer == 0 &&
            !is_wallet_redeem &&
            !fullDay
          "
                :disabled="repeatId && !isEnableRepeateBookingReschedule"
                @click="reschedule()">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Reschedule">
                <template #icon>
                  <RescheduleIcon height="16" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item
                v-if="
            !repeatId &&
            bookingDetails.is_trainer != 1 &&
            current_group_customer.check_in_time == null

          "
                @click="checkInAndOut(current_group_customer.group_customer_id)">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Check In">
                <template #icon>
                  <CheckInIcon height="16" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item
                v-if="
            !repeatId &&
            bookingDetails.is_trainer != 1 &&
            (current_group_customer.check_in_time != null &&
              current_group_customer.check_out_time == null)

          "
                @click="checkInAndOut(current_group_customer.group_customer_id)">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Check Out">
                <template #icon>
                  <CheckOutIcon height="16" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item
                v-if="
            !repeatId &&
            bookingDetails.is_trainer != 1 &&
            (current_group_customer.check_in_time != null &&
              current_group_customer.check_out_time == null)

          "
                @click="voidCheckIn(current_group_customer.group_customer_id)">
              <SvgIcon class="font-medium text-sm gap-x-2" text="Void Check In">
                <template #icon>
                  <VoidCheckInIcon height="16" viewBox="0 0 20 20" width="16"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-divider class="mb-2"/>
            <v-list-item v-if="!bookingDetails.is_credit && bookingDetails.is_booking_approved == 0"
                         @click="confirmReject">
              <SvgIcon class="font-medium text-sm gap-x-2 red--text" text="Reject Booking">
                <template #icon>
                  <DeleteIcon opacity="1" stroke="red"/>
                </template>
              </SvgIcon>
            </v-list-item>
            <v-list-item v-if="!bookingDetails.is_credit && bookingDetails.is_booking_approved == 1"
                         @click="confirmCancel">
              <SvgIcon class="font-medium text-sm gap-x-2 red--text" text="Cancel Booking">
                <template #icon>
                  <DeleteIcon opacity="1" stroke="red"/>
                </template>
              </SvgIcon>
            </v-list-item>
          </v-list>
        </v-menu>


        <!--        <v-btn-->
        <!--          v-if="bookingDetails.is_trainer == 0"-->
        <!--          @click="showAddOnProduct = true"-->
        <!--          class="ma-2 white&#45;&#45;text blue-color"-->
        <!--          text-->
        <!--          >Add On Product-->
        <!--        </v-btn>-->
        <!--        <v-btn-->
        <!--          v-if="-->
        <!--            perCapacity != 1 &&-->
        <!--            bookingDetails.is_trainer == 0 &&-->
        <!--            !is_wallet_redeem &&-->
        <!--            !fullDay-->
        <!--          "-->
        <!--          @click="reschedule()"-->
        <!--          :disabled="repeatId && !isEnableRepeateBookingReschedule"-->
        <!--          class="ma-2 white&#45;&#45;text teal-color"-->
        <!--          text-->
        <!--          >Reschedule-->
        <!--        </v-btn>-->

        <!--        <v-btn-->
        <!--          :class="`ma-2 red-color`"-->
        <!--          dark-->
        <!--          text-->
        <!--          @click="confirmCancel"-->
        <!--          v-if="-->
        <!--            !bookingDetails.is_credit && bookingDetails.is_booking_approved == 1-->
        <!--          "-->
        <!--          >Cancel Booking</v-btn-->
        <!--        >-->
        <!--        <v-btn-->
        <!--          :class="`ma-2 red-color`"-->
        <!--          dark-->
        <!--          text-->
        <!--          @click="confirmReject"-->
        <!--          v-if="-->
        <!--            !bookingDetails.is_credit && bookingDetails.is_booking_approved == 0-->
        <!--          "-->
        <!--          >Reject Booking</v-btn-->
        <!--        >-->
        <!--        <v-btn-->
        <!--          v-if="-->
        <!--            bookingDetails.alert_notes != null &&-->
        <!--            bookingDetails.alert_notes != ''-->
        <!--          "-->
        <!--          class="ma-1 white&#45;&#45;text red-color"-->
        <!--          @click="openAlert(bookingDetails.customer_id)"-->
        <!--        >-->
        <!--          Alert-->
        <!--        </v-btn>-->
        <v-spacer></v-spacer>

<!--        <v-btn-->
<!--            v-show="-->
<!--            !repeatId &&-->
<!--            bookingDetails.is_trainer != 1 &&-->
<!--            (current_group_customer.check_in_time == null ||-->
<!--              current_group_customer.check_out_time == null)-->
<!--          "-->
<!--            :color="-->
<!--            current_group_customer.check_in_and_out == 'OUT'-->
<!--              ? 'error'-->
<!--              : 'success'-->
<!--          "-->
<!--            @click="checkInAndOut(current_group_customer.group_customer_id)"-->
<!--            class="ma-1 white&#45;&#45;text teal-color"-->
<!--        >-->
<!--          {{-->
<!--            current_group_customer.check_in_and_out-->
<!--                ? "Check " +-->
<!--                (current_group_customer.check_in_and_out == "OUT"-->
<!--                    ? "Out"-->
<!--                    : "In")-->
<!--                : "Check In/Out"-->
<!--          }}-->
<!--        </v-btn-->
<!--        >-->
        <v-btn @click="close()" class="ma-2 text-capitalize" text
        >Close
        </v-btn>
        <v-btn
            v-if="
           repeatId &&
            !bookingWithOpenProduct &&
            checkWritePermission($modules.sales.refund.slug)
          "
            class="ma-2 red-color"
            dark
            text
            @click="refundAndCancel"
        >Refund
        </v-btn>

        <template v-if="bookingDetails.is_trainer == 1">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  :class="
                  bookingDetails.is_present == 0
                    ? 'default-color'
                    : bookingDetails.is_present == 1
                    ? 'green-color'
                    : 'red-color'
                "
                  dark
                  class="text-decoration-none"
                  v-bind="attrs"
                  v-on="on"
              >
                {{
                  bookingDetails.is_present == 0
                      ? "Attendance"
                      : bookingDetails.is_present == 1
                          ? "Attended"
                          : "No show"
                }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  @click="
                  confirmAttendance({
                    facility_booking_id: bookingDetails.id,
                    operation: 'present',
                  })
                "
              >
                <v-list-item-title>Mark as attended</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="
                  confirmAttendance({
                    facility_booking_id: bookingDetails.id,
                    operation: 'absent',
                  })
                "
              >
                <v-list-item-title>Mark no show</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn @click="confirmRefund" class="ma-2 yellow-color" text
          >Refund Session
          </v-btn>
        </template>
<!--        <v-btn-->
<!--            v-if="bookingDetails.is_booking_approved == 0"-->
<!--            @click="approveBooking"-->
<!--            class="ma-2 white&#45;&#45;text green-color"-->
<!--            text-->
<!--        >-->
<!--          Approve-->
<!--        </v-btn>-->
        <v-btn
            v-if="bookingDetails.is_trainer == 0"
            class="ma-2 white--text blue-color"
            text
            @click="receipt"
        >
          {{
            bookingDetails.status_id == 4 ||
            bookingDetails.status_id == 14 ||
            bookingDetails.status_id == 21
                ? "Receipt"
                : "Invoice"
          }}
        </v-btn>
        <v-menu offset-y v-if="this.perCapacity == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="ma-2 white--text blue-color"
                text
                v-on="on"
                v-bind="attrs"
            >Tickets
              <v-icon right dark> mdi-menu-down</v-icon>
            </v-btn
            >
          </template>
          <v-list>
            <!-- Download Section -->
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-title>Download</v-list-item-title>
                  <v-icon right dark> mdi-menu-down</v-icon>
                </v-list-item>
              </template>
              <v-list>
                <v-list-item @click="downloadPdf('normal')">
                  <v-list-item-title>POS</v-list-item-title>
                </v-list-item>
                <v-list-item @click="downloadPdf('boca')">
                  <v-list-item-title>BOCA</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- Print Section -->
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-on="on" v-bind="attrs" @click="viewTicketsPopup">
                  <v-list-item-title>View Tickets</v-list-item-title>
                  <v-icon right dark> mdi-menu-down</v-icon>
                </v-list-item>
              </template>
            </v-menu>
            <!-- <v-list-item @click="downloadPdf('normal')">
              <v-list-item-title>Download</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadPdf('boca')">
              <v-list-item-title>Print</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <v-btn
            v-if="repeatId && !bookingWithOpenProduct"
            @click="showRepeatPaymentsConfirmation"
            class="ma-2 white--text blue-color"
            text
            :disabled="repeatId && !isEnableRepeatBookingPayment"
        >
          Pay
        </v-btn>
      </v-card-actions>
    </v-card>

    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>

    <add-on-product
        v-if="showAddOnProduct"
        :key="bookingDetails.order_id"
        v-bind="bookingDetails"
        :show="showAddOnProduct"
        :venue_service_id="venueServiceId"
        :facilityId="bookingDetails.facilityId"
        @close="showAddOnProduct = false"
        @confirmPurchaseAddOnProduct="confirmPurchaseAddOnProduct"
        :booking-details="bookingDetails"
        :per-capacity="perCapacity"
    ></add-on-product>

    <repeat-order-payments
        :promotion_code="bookingDetails.promotion_code"
        :card_number="bookingDetails.card_number"
        v-if="enableRepeatOrderPay"
        v-bind="rentalProductDeteails"
        :show="enableRepeatOrderPay"
        :repeatBookingIds="repeatBookingIds"
        :wallet="wallet"
        :orderId="id"
        :payerCustomerList="payerCustomerList"
        @close="enableRepeatOrderPay = false"
        @payed="getBookingDetails(), (enableRepeatOrderPay = false)"
    ></repeat-order-payments>
    <v-dialog width="55%" scrollable v-model="participantDialog">
      <v-card>
        <v-card-title class="headline">Participants</v-card-title>
        <v-card-text>
          <v-card
              outlined
              class="pa-4 mt-2"
              v-for="(customer, index) in bookingDetails.group_customers"
              :key="customer.customer_id + index"
          >
            <v-row>
              <v-col md="4">
                <div class="details_subtitle">Customer Name</div>
                <div class="details_data">
                  <v-btn
                      small
                      style="max-width: 200px"
                      class="text-truncate"
                      color="#00b0af"
                      dark
                      @click="showUserModel(customer.customer_id)"
                  >{{ customer.name }}
                  </v-btn>
                </div>
              </v-col>
              <v-col md="4">
                <div class="details_subtitle">Mobile</div>
                <div class="details_data">
                  {{ customer.mobile }}
                </div>
              </v-col>
              <v-col md="4">
                <div class="details_subtitle">Email</div>
                <div class="details_data">
                  {{ customer.email || "NA" }}
                </div>
              </v-col>
              <v-col md="4">
                <div class="details_subtitle">Gender</div>
                <div class="details_data">
                  {{ customer.gender || "NA" }}
                </div>
              </v-col>
              <v-col md="4">
                <div class="details_subtitle">Date of Birth</div>
                <div class="details_data">
                  <span v-if="customer.dob">{{
                      customer.dob | dateformat
                    }}</span>
                  <span v-else> NA</span>
                </div>
              </v-col>
              <v-col md="4">
                <div class="details_subtitle">Nationality</div>
                <div class="details_data">
                  {{ customer.country || "NA" }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              @click="participantDialog = false"
              class="ma-2 white--text blue-color"
              text
          >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <customer-alert-note
        v-bind="alertNote"
        :id="alertNote.id"
        :note="alertNote.note"
        @clearAlertNote="clearAlertNote"
        @closeAlert="closeAlert"
    >
    </customer-alert-note>
    <TicketPopupFacilityBooking
        :showParticipants="showParticipants"
        :id="id"
        @close="showParticipants = false"
    />
    <v-dialog
        v-model="showRejectionModal"
        width="400px"
        scrollable
        @input="closeRejectionModal"
    >
      <v-card>
        <v-card-title class="headline"
        >Please add reason for rejection
        </v-card-title
        >
        <v-card-text class="pa-8">
          <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="submitRejectionReason"
              lazy-validation
          >
            <v-select
                hide-details="auto"
                v-model="rejectionReasonType"
                :items="[
                'Conflicting Schedule',
                'Unavailability of Tour Guide',
                'Weather Conditions',
                'Health and Safety Concerns',
                'Private Event Conflicts',
                'Resource Constraints',
                'Cancellation Requested by Customer',
                'Other (Please Specify)',
              ]"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Rejection Reason is required']"
                class="q-autocomplete shadow-0 mb-2"
                dense
                validate-on-blur
            >
            </v-select>
            <v-textarea
                name="rejection_reason"
                label="Rejection reason"
                v-model="rejectionReason"
                v-if="rejectionReasonType === 'Other (Please Specify)'"
                outlined
                background-color="#fff"
                :rules="[(v) => !!v || 'Rejection reason is required']"
            ></v-textarea>
            <div class="d-flex justify-end">
              <v-btn class="red-color" dark text type="submit"> Reject</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import RepeatBookingPayment from "@/components/Schedule/Facility/RepeatBookingPayment";
import RepeatOrderPayments from "@/components/Order/RepeatOrderPayments.vue";
import AddOnProduct from "@/components/Facility/AddOnProduct.vue";
import bookingFields from "@/mixins/bookingFieldValidation";
import CustomerAlertNote from "@/views/Clients/Customer/CustomerAlertNote.vue";
import TicketPopupFacilityBooking from "@/views/Schedule/Facility/TicketPopupFacilityBooking.vue";
import {mapGetters} from "vuex";
import moment from "moment";
import EditIcon from "@/assets/images/misc/edit-icon.svg";
import AlertIcon from "@/assets/images/misc/alert-icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DotsIcon from "@/assets/images/misc/more-options.svg";
import DeleteIcon from "@/assets/images/misc/delete-icon.svg";
import RescheduleIcon from "@/assets/images/misc/reschedule.svg";
import CheckInIcon from "@/assets/images/misc/location-icon.svg";
import CheckOutIcon from "@/assets/images/misc/checkout.svg";
import VoidCheckInIcon from "@/assets/images/misc/void-checkin.svg";
import ModalCloseIcon from "@/assets/images/misc/modal-close.svg";

export default {
  props: {
    id: {type: Number, default: 0},
    venueServiceId: {type: Number},
    is_split_payment: {type: Number, default: 0},
  },
  computed: {
    ...mapGetters({
      isCreditPayment: "isCreditPayment",
    }),
    idProofTypes() {
      return this.$store.getters.getIdProofTypes.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    venueServiceConfiguration() {
      return this.$store.getters.getConfigurationByVenueServiceId(
          this.venueServiceId
      );
    },

  },
  components: {
    ModalCloseIcon,VoidCheckInIcon,
    DeleteIcon,RescheduleIcon,CheckOutIcon, CheckInIcon, DotsIcon, SvgIcon, EditIcon,AlertIcon,
    RepeatBookingPayment,
    RepeatOrderPayments,
    AddOnProduct,
    CustomerAlertNote,
    TicketPopupFacilityBooking,
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$store.getters.getIdProofTypes.status == false) {
      this.$store.dispatch("loadIDProofTypes");
    }
    if (this.$store.getters.getPaymentMethods.status == false) {
      this.$store.dispatch("loadPaymentMethods", "normal");
    }
  },
  mixins: [bookingFields],
  data() {
    return {
      wallet: {
        products: null,
        cash: null,
      },
      showParticipants: false,
      showAddOnProduct: false,
      fullDay: false,
      rentalProductDeteails: {},
      isEnableRepeatBookingPayment: false,
      isEnableRepeateBookingReschedule: false,
      enableRepeatOrderPay: false,
      repeatDatesForPayments: null,
      bookingDetails: {group_customers: []},
      dialog: false,
      repeatId: null,
      repeatBookingIds: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      isPastTime: false,
      isPastDate: false,
      participantDialog: false,
      perCapacity: 0,
      discountApplied: false,
      bookingWithOpenProduct: false,
      venue_service_id: null,
      is_wallet_redeem: false,
      alertNote: {
        show: false,
        note: null,
        id: null,
      },
      current_group_customer: {},
      payerCustomerList: [],
      uploadedAttachments: [],
      showRejectionModal: false,
      rejectionReason: "",
      rejectionReasonType: null,
      valid: true,
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val == null) this.dialog = false;
        if (val) {
          this.getBookingDetails();
          // this.getCustomerWallets();
        }
      },
    },
  },
  methods: {
    downloadPdf(type) {
      this.showLoader("Generating..");
      this.$http
          .get(`venues/orders/booking-tickets/download/${this.id}/${type}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              // this.printFile(response);
              // console.log(response.data);
              if (response.data.type === "application/zip") {
                this.downloadFile(response, "Ticket_export_", "zip");
              } else {
                this.downloadFile(response, "Ticket_export_", "pdf");
              }
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    viewTicketsPopup() {
      this.showParticipants = true;
    },
    printPdf(type) {
      this.showLoader("Generating..");
      this.$http
          .get(`venues/orders/booking-tickets-pdf/${this.id}?type=${type}`, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.status == 200) {
              this.printFile(response);
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    checkInAndOut(id) {
      this.showLoader("Loading");
      this.$http
          .get(`venues/facilities/bookings/check-in-and-out/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.getBookingDetails();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    voidCheckIn(id){
      this.$http
          .get(`venues/facilities/bookings/void-check-in/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.getBookingDetails();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    getCustomerWallets() {
      if (!this.id) {
        return;
      }
      this.$http
          .get(`venues/customers/products-wallet/get-customer-all/${this.id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.hideLoader();
              let data = response.data;
              this.wallet.cash = data.cash;
              this.wallet.products = data.products;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    openAlert(id) {
      this.showLoader();
      this.$http
          .get("venues/customers/get-alert-note/" + id)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.alertNote.id = response.data.data.customer_id;
              this.alertNote.note = response.data.data.alert_notes;
              this.alertNote.show = true;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    clearAlertNote(id) {
      this.showLoader();
      this.$http
          .post("venues/customers/clear-alert-note/" + id)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200) {
              this.showSuccess("Customer alert note cleared!");
              this.getBookingDetails();
              this.closeAlert();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    closeAlert() {
      this.alertNote.show = false;
    },
    checkFullDayProduct() {
      const hasFullDay = this.bookingDetails.products.some(
          (obj) => obj.is_full_day === 1
      );
      if (hasFullDay) {
        this.fullDay = true;
      } else {
        this.fullDay = false;
      }
    },
    getBookingDetails() {
      this.bookingDetails = {group_customers: []};
      this.isEnableRepeateBookingReschedule = false;
      this.repeatId = null;
      this.repeatDatesForPayments = null;
      this.showLoader("Loading Details");
      this.facilities = [];
      this.$http
          .get(`venues/facilities/bookings/${this.id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              const data = response.data.data;
              this.bookingWithOpenProduct = false;
              if (data.wallet_redemption && data.wallet_redemption.length > 0) {
                this.is_wallet_redeem = true;
              } else {
                this.is_wallet_redeem = false;
              }
              if (data.order_status_id == 13) {
                this.$emit("cancelRepeatBooking");
              }
              this.uploadedAttachments =
                  data.facility_booking.length > 0 && data.facility_booking[0]
                      ? data.facility_booking[0].documents
                      : [];
              this.bookingDetails.group_customers = data.group_customers;
              if (data.facility_booking.length > 0) {
                const bookingData = data.facility_booking[0];
                this.bookingDetails.id = bookingData.id;
                this.bookingDetails.customer_type = bookingData.customer_type;
                this.bookingDetails.is_booking_approved =
                    bookingData.is_booking_approved;
                this.bookingDetails.alert_notes = data.customer.alert_notes;

                this.bookingDetails.image_path = bookingData.image_path;
                this.bookingDetails.facility_booking_id = bookingData.id;
                this.bookingDetails.start_time = bookingData.start_time;
                this.bookingDetails.end_time = bookingData.end_time;
                this.bookingDetails.facility = bookingData.facility.name;
                this.bookingDetails.created_at = data.created_at;
                this.bookingDetails.sales_person = data.sales_team_id && data.sales_team  ? data.sales_team.user.first_name + ' ' + data.sales_team.user.last_name : 'N/A';
                this.bookingDetails.sales_team_id = data.sales_team_id;

                this.perCapacity = bookingData.facility.per_capacity;


                const time1 = moment(bookingData.start_time, 'HH:mm:ss');
                const time2 = moment(bookingData.end_time, 'HH:mm:ss'); // Change this to your second time

                const differenceInMinutes = time2.diff(time1, 'minutes');

                if (differenceInMinutes === 1) {
                  let timeToAdd = 30;
                  if (this.venueServiceConfiguration) {
                    timeToAdd = this.venueServiceConfiguration.time_increment;
                  }
                  let end_time = time1.clone().add(timeToAdd, 'minutes');
                  this.bookingDetails.end_time = end_time.format('HH:mm:ss');
                }

                this.bookingDetails.facilityId = bookingData.facility.id;
                this.bookingDetails.is_trainer = bookingData.is_trainer;
                this.bookingDetails.is_present = 0;
                if (this.bookingDetails.is_trainer == 1) {
                  this.bookingDetails.is_present = bookingData.is_present;
                }
                this.repeatId = bookingData.repeat ? bookingData.repeat.id : null;
                this.bookingDetails.date = bookingData.date;
                //this.bookingDetails.id_proof = bookingData.id_proof;
                this.bookingDetails.id_proof_url = bookingData.id_proof;
                if (bookingData.id_proof)
                  this.bookingDetails.id_proof_name = bookingData.id_proof
                      .split("/")
                      .pop();
                if (bookingData.id_proof) {
                  this.bookingDetails.id_proof = new File(
                      [""],
                      this.bookingDetails.id_proof_name
                  );
                }
                this.bookingDetails.id_proof_number = bookingData.id_proof_number;
                this.bookingDetails.id_proof_type_id =
                    bookingData.id_proof_type_id;
                this.bookingDetails.card_expiry_date =
                    bookingData.card_expiry_date;
                this.bookingDetails.card_type = bookingData.card_type;
                this.bookingDetails.terms_conditions_url =
                    bookingData.terms_conditions;
                if (bookingData.terms_conditions) {
                  this.bookingDetails.terms_conditions = new File(
                      [""],
                      bookingData.terms_conditions
                  );
                }
                if (bookingData.id_proof_type_id) {
                  if (this.$store.getters.getIdProofTypes.status == false) {
                    this.$store.dispatch("loadIDProofTypes");
                  } else {
                    var documentType =
                        this.$store.getters.getIdProofTypes.data.find(
                            (x) => x.id == bookingData.id_proof_type_id
                        );
                    this.bookingDetails.id_type = documentType.name;
                  }
                }
              }
              // this.bookingDetails.payments = data.payments;
              if (
                  data.invoice &&
                  data.invoice.length > 0 &&
                  data.invoice[0].payments.length > 0
              ) {
                this.bookingDetails.payments = data.invoice[0].payments;
              }

              this.bookingDetails.order_notes = data.order_notes;
              if (data.facility_booking.length > 1) {
                this.bookingDetails.repeat_dates = [];
                this.bookingDetails.repeat = true;

                data.facility_booking.forEach((data) => {
                  this.bookingDetails.repeat_dates.push({
                    id: data.id,
                    date: data.date,
                  });
                });
              }
              this.bookingDetails.promotion_code = null;
              this.bookingDetails.card_number = null;
              if (data.discount != null) {
                if (data.discount.promotion != null) {
                  this.bookingDetails.promotion_code =
                      data.discount.promotion.promotion_code;
                }
                if (data.discount.member != null) {
                  this.bookingDetails.card_number =
                      data.discount.member.card_number;
                }
              }
              if (data.parent_orders_id) {
                this.bookingDetails.parent_orders_id = data.parent_orders_id;
              } else {
                this.bookingDetails.parent_orders_id = null;
              }

              if (data.customer) {
                this.bookingDetails.name = `${data.customer.first_name}${
                    data.customer.last_name ? " " + data.customer.last_name : ""
                }`;
                this.bookingDetails.first_name = data.customer.first_name;
                this.bookingDetails.last_name = data.customer.last_name;
                this.bookingDetails.mobile = data.customer.mobile;
                this.bookingDetails.email = data.customer.email;
                this.bookingDetails.gender = data.customer.gender;
                this.bookingDetails.dob = data.customer.dob;
                this.bookingDetails.mobile = data.customer.mobile;
                this.bookingDetails.country_id = data.customer.country_id;
                this.bookingDetails.country = data.customer.country;
              }

              this.bookingDetails.price = data.price;
              this.bookingDetails.tax = data.tax;
              this.bookingDetails.total = data.total;
              this.bookingDetails.status_id = data.status_id;
              this.bookingDetails.order_id = data.id;
              this.bookingDetails.customer_id = data.customer_id;

              this.bookingDetails.products = [];
              let productItems = [];
              productItems = this.repeatId
                  ? data.repeat_booking_items
                  : data.facility_booking_items
                      ? data.facility_booking_items
                      : data.items;
              productItems.forEach((product) => {
                let pdata = {
                  order_item_id: product.id,
                  product_id: product.product_id,
                  product_type_id: product.product_type_id,
                  category_id: product.category_id,
                  quantity: product.updated_quantity
                      ? product.updated_quantity
                      : product.quantity,
                  product_price: product.product_price,
                  price: product.price,
                  total: product.total,
                  tax: product.tax,
                  image: product.image,
                  rental: product.rental != null ? true : false,
                  is_full_day:
                      product.rental != null ? product.rental.is_full_day : false,
                  venue_service_id: this.venue_service_id,
                  discount: product.discount != null ? product.discount : false,
                  name: product.name ? product.name : "Product Name",
                  order_seq_no: product.order_seq_no,
                  invoice_seq_no: product.invoice_seq_no,
                };
                if (
                    product.category_name == "open_product" &&
                    product.rental != null
                ) {
                  this.bookingWithOpenProduct = true;
                }
                this.bookingDetails.products.push(pdata);
              });

              if (data.credit_order) {
                this.bookingDetails.is_credit = true;
              } else {
                this.bookingDetails.is_credit = false;
              }

              if (this.repeatId || data.facility_booking_items) {
                this.bookingDetails.price = this.bookingDetails.products.reduce(
                    (a, b) => a + parseFloat(b.price),
                    0
                );
                this.bookingDetails.tax = this.bookingDetails.products.reduce(
                    (a, b) => a + parseFloat(b.tax ? b.tax : 0),
                    0
                );

                this.bookingDetails.total =
                    this.bookingDetails.price + this.bookingDetails.tax;
              }

              const currentDate = moment(new Date()).format("YYYY-MM-DD");
              this.isPastTime = moment(
                  this.bookingDetails.end_time,
                  "HH:mm:ss"
              ).isSameOrBefore();
              this.isPastDate = moment(currentDate, "YYYY-MM-DD")
                  .startOf("day")
                  .isSameOrBefore(this.bookingDetails.date);
              // console.log(data.items);
              this.venue_service_id = data.items[0].venue_service_id;
              if (data.group_customers.length > 0) {
                this.current_group_customer = data.group_customers[0];
              }
              this.setFieldConfigurations();
              this.dialog = true;
              this.checkFullDayProduct();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    close() {
      this.dialog = false;
      console.log("closing booking details")
      this.$emit("close");
    },
    closeRejectionModal() {
      this.showRejectionModal = false;
      this.rejectionReason = null;
    },
    receipt() {
      console.log("Split");
      console.log(this.is_split_payment);
      if (
          this.bookingDetails.parent_orders_id &&
          this.bookingDetails.parent_orders_id.length > 0 &&
          this.is_split_payment == 0
      ) {
        this.$emit(
            "repeatBookingReceipt",
            this.bookingDetails.parent_orders_id.sort((a, b) => b - a)
        );
      } else {
        this.$emit("receipt", this.id);
      }
    },

    reschedule() {
      if (this.isEnableRepeateBookingReschedule) {
        const bookings = this.repeatDatesForPayments.filter(
            (item) => item.isPaid
        );
        if (bookings && bookings.length > 1) {
          const bookingIds = bookings.map((item) => item.booking_id);
          this.$emit("rescheduleMulti", {booking_ids: bookingIds});
        } else {
          this.$emit("reschedule", bookings[0].booking_id);
        }
      } else {
        this.$emit("reschedule", this.bookingDetails.id);
      }
    },

    refundAndCancel() {
      if (!this.repeatId) {
        if (
            this.bookingDetails.parent_orders_id &&
            this.bookingDetails.parent_orders_id.length > 0
        ) {
          this.$emit(
              "repeatBookingReceipt",
              this.bookingDetails.parent_orders_id.sort((a, b) => b - a)
          );
        } else {
          this.$emit("receipt", this.id);
        }
        // this.$emit("refund", this.id);
      } else if (
          this.repeatId &&
          this.repeatDatesForPayments.filter(
              (element) => element.isPaid && element.status_id == 5
          ).length
      ) {
        this.showError("Please select only the paid slot");
        return;
      } else if (
          this.repeatId &&
          this.repeatDatesForPayments.filter((element) => element.isPaid).length >
          0
      ) {
        this.confirmModel = {
          id: this.bookingDetails.order_id,
          title: `Do you want refund this booking?`,
          description: `This will refund selected booking. By clicking <b>Yes</b> you can confirm refund operation`,
          type: "repeatBookingRefund",
        };
      } else {
        this.showError("Please select date for refund");
      }
    },
    confirmAttendance(data) {
      // console.log(data);
      let msg =
          "This will mark the attendance of the customer. By clicking Yes you can confirm the operation.";
      this.confirmModel = {
        id: this.bookingDetails.id,
        title: `Do you want to mark the attendance?`,
        description: msg,
        type: "attendance",
        data: data,
      };
    },
    confirmRefund() {
      let msg =
          "This will refund the scheduled session to customer training package. By clicking Yes you can confirm the operation.";
      this.confirmModel = {
        id: this.bookingDetails.id,
        title: `Do you want to refund the session?`,
        description: msg,
        type: "refund",
      };
    },
    confirmActions(data) {
      if (data.type == "attendance") {
        this.markAttendance(data.data);
      } else if (data.type == "cancel") {
        this.$emit("cancel", data.id);
      } else if (data.type == "repeatBookingCancel") {
        this.multipleCancelForRepeatBooking();
      } else if (data.type == "repeatBookingRefund") {
        this.multipleRefundForRepeatBooking();
      } else if (data.type == "refund") {
        this.refundSession(data.id);
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },

    multipleCancelForRepeatBooking() {
      let bookingIds = [];

      this.repeatDatesForPayments.forEach((el) => {
        if (el.isPaid) {
          bookingIds.push({
            booking_id: el.booking_id,
            date: el.date,
            order_id: el.order_id,
            status_id: el.status_id,
            facility_id: el.facility_id,
            end_time: el.end_time,
            start_time: el.start_time,
          });
        }
      });
      this.showLoader();

      this.$http
          .post(`venues/facilities/bookings/repeat/multiple/cancel`, {
            booking_ids: bookingIds,
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Booking cancelled successfully");
              if (
                  this.repeatDatesForPayments.filter((element) => element.isPaid)
                      .length ==
                  this.repeatDatesForPayments.filter(
                      (element) => element.status_id != 2
                  ).length
              ) {
                this.$emit("cancelRepeatBooking");
              } else {
                this.close();
                // this.getBookingDetails();
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    multipleRefundForRepeatBooking() {
      let bookingIds = [];

      this.repeatDatesForPayments.forEach((el) => {
        if (el.isPaid) {
          bookingIds.push({
            booking_id: el.booking_id,
            date: el.date,
            order_id: el.order_id,
            status_id: el.status_id,
            facility_id: el.facility_id,
            end_time: el.end_time,
            start_time: el.start_time,
          });
        }
      });

      if (
          this.repeatDatesForPayments.filter(
              (element) => element.isPaid && element.status_id == 1
          ).length
      ) {
        this.showLoader();
        this.$http
            .post(`venues/facilities/bookings/repeat/check-refund-amount`, {
              booking_ids: bookingIds,
            })
            .then((response) => {
              if (response.status == 200 && response.data.status == true) {
                this.hideLoader();
                if (response.data.total_amount) {
                  this.$emit(
                      "repeatRefundAndCancel",
                      response.data.total_amount,
                      bookingIds
                  );
                } else {
                  this.showError("No Amount found, please cancel the booking");
                }
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      }
    },

    markAttendance(data) {
      this.showLoader("Loading...");
      var formData = new FormData();
      formData.append("facility_booking_id", data.facility_booking_id);
      this.$http
          .post(
              `venues/trainers/customers/sessions/attendance/${data.operation}`,
              formData
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              const data = response.data.data;
              this.bookingDetails.is_present = data.is_present;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    refundSession(id) {
      this.showLoader("Loading...");
      var formData = new FormData();
      formData.append("facility_booking_id", id);
      this.$http
          .post(`venues/trainers/customers/sessions/refund`, formData)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.close();
              this.$emit("refundSession");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    confirmReject() {
      this.showRejectionModal = true;
    },
    confirmCancel() {
      if (!this.repeatId || (this.repeatId && this.bookingWithOpenProduct)) {
        this.confirmModel = {
          id: this.bookingDetails.order_id,
          title: `Do you want cancel this booking?`,
          description: `This will cancel current booking. By clicking <b>Yes</b> you can confirm cancel operation`,
          type: "cancel",
        };
        // this.close();
      } else if (
          this.repeatId &&
          this.repeatDatesForPayments.filter((element) => element.isPaid).length >
          0
      ) {
        this.confirmModel = {
          id: this.bookingDetails.order_id,
          title: `Do you want cancel this booking?`,
          description: `This will cancel current booking. By clicking <b>Yes</b> you can confirm cancel operation`,
          type: "repeatBookingCancel",
        };
        // this.close();
      } else {
        this.showError("Please select date for cancel");
      }
    },
    // confirmActions() {
    //   this.$emit("cancel", this.id);
    //   this.$forceUpdate();
    //   this.confirmModel.id = null;
    // },

    showRepeatPaymentsConfirmation() {
      let unpaidOrderLength = this.repeatDatesForPayments.filter(
          (element) => element.isPaid && element.status_id == 5
      ).length;
      let paidOrderLength = this.repeatDatesForPayments.filter(
          (element) => element.isPaid && element.status_id == 1
      ).length;
      if (paidOrderLength > 0) {
        this.showError("Please unselect paid date");
      } else if (unpaidOrderLength > 0) {
        var findUnpaidOrders = this.repeatDatesForPayments.filter(
            (x) => x.status_id == 5
        );
        if (findUnpaidOrders.length == unpaidOrderLength) {
          this.$emit("receipt", findUnpaidOrders[0].order_id);
        } else {
          this.setPayerCustomerList();
          this.multiplePayment();
        }
      } else {
        this.showError("Please select date for pay");
      }
    },

    multiplePayment() {
      if (
          this.repeatDatesForPayments.filter(
              (element) => element.isPaid && element.status_id == 5
          ).length > 0
      ) {
        this.repeatBookingIds = [];
        this.repeatDatesForPayments.forEach((el) => {
          if (el.isPaid && el.status_id == 5) {
            this.repeatBookingIds.push(el);
          }
        });

        if (
            !this.discountApplied &&
            (this.bookingDetails.promotion_code ||
                this.bookingDetails.card_number)
        ) {
          let data = {
            products: [],
          };
          data.mobile = this.bookingDetails.mobile;
          if (this.bookingDetails.promotion_code) {
            data.promotion_code = this.bookingDetails.promotion_code;
          } else {
            data.card_number = this.bookingDetails.card_number;
          }
          data.products = this.rentalProductDeteails.products;
          data.products.forEach((element) => {
            if (element.product_price) {
              element.price = element.product_price;
            }
          });
          let url = "venues/benefits/verify";
          this.$http
              .post(url, data)
              .then((response) => {
                if (response.status == 200 && response.data.status == true) {
                  const data = response.data.data;
                  // console.log(data);
                  this.rentalProductDeteails.products = data.products;
                  this.discountApplied = true;
                  this.enableRepeatOrderPay = true;
                }
              })
              .catch((error) => {
                this.errorChecker(error);
              });
        } else {
          this.enableRepeatOrderPay = true;
        }
      }
    },

    repeatDatesForPaymentChange(dates, rentalCombination) {
      this.repeatDatesForPayments = dates;
      if (dates.filter((element) => element.isPaid).length == 1) {
        this.isEnableRepeatBookingPayment = true;
        this.isEnableRepeateBookingReschedule = true;
      } else if (dates.filter((element) => element.isPaid).length == 0) {
        this.isEnableRepeatBookingPayment = false;
        this.isEnableRepeateBookingReschedule = false;
      } else {
        this.isEnableRepeatBookingPayment = true;
        this.isEnableRepeateBookingReschedule = true;
      }
      this.discountApplied = false;
      if (rentalCombination && rentalCombination.products.length > 0) {
        this.rentalProductDeteails.products = [];
        rentalCombination.products.forEach((product) => {
          this.rentalProductDeteails.products.push({
            product_id: product.id,
            product_type_id: product.product_type_id,
            price: product.price,
            name: product.name,
            tax: product.tax_amount,
            category_id: product.category_id,
            rental: true,
            product_price: product.product_price,
            quantity: product.quantity,
            total_tax_amount: product.total_tax_amount
                ? product.total_tax_amount
                : null,
            total_price:
                (product.price + product.tax_amount) * product.quantity,
          });
        });
      }
    },

    showUserModel(userId) {
      this.$emit("openCustomerProfile", userId);
    },

    confirmPurchaseAddOnProduct(orderId) {
      this.$emit("receipt", orderId);
    },
    paymentMethods(id) {
      // console.log(this.bookingDetails.payments);
      // console.log("----------------payment method id--------------------");
      // console.log(id);
      // console.log("------------------------------------");
      if (id) {
        // console.log(this.$store.getters.getPaymentMethods.data);
        var val = this.$store.getters.getPaymentMethods.data.find(
            (x) => x.id == id
        );
        return val ? val.name : null;
      }
    },
    saveCustomer() {
      this.showLoader("Loading Details");
      var formData = new FormData();
      for (let key in this.bookingDetails) {
        if (this.bookingDetails[key]) {
          formData.append(key, this.bookingDetails[key]);
        } else if (key == "terms_conditions") {
          // console.log(key);
          // console.log(this.bookingDetails[key]);
          if (this.bookingDetails[key] == undefined) formData.append(key, "");
          else formData.append(key, this.bookingDetails[key]);
        } else if (key == "id_proof") {
          // console.log(key);
          // console.log(this.bookingDetails[key]);
          if (this.bookingDetails[key] == undefined) formData.append(key, "");
          else formData.append(key, this.bookingDetails[key]);
        }
        // console.log(key);
      }
      this.userID = this.bookingDetails.customer_id;
      this.$http({
        method: "post",
        data: formData,
        url: "venues/customers/" + this.bookingDetails.customer_id,
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
          .then((response) => {
            if (response.status == 200) {
              this.hideLoader();
              this.editDialog = false;
              this.getBookingDetails();
              this.showSuccess("Customer Details Updated.");
              this.$emit("updated");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    setPayerCustomerList() {
      this.payerCustomerList = [];
      if (this.bookingDetails.customer_id) {
        this.payerCustomerList.push({
          customerId: this.bookingDetails.customer_id,
          customerName: this.bookingDetails.name,
          mobile: this.bookingDetails.mobile,
          email: this.bookingDetails.email,
          cashWallet: this.wallet.cash,
        });
      }
    },
    approveBooking() {
      this.showLoader("Loading");
      this.$http
          .get(
              `venues/facilities/bookings/booking-status/${this.bookingDetails.id}/approve`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("Booking Approved.");
              this.$emit("refresh");
              this.getBookingDetails();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    submitRejectionReason() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader("Loading");
      this.$http
          .delete(`venues/orders/${this.id}`, {
            data: {
              rejection_reason: this.rejectionReason,
              rejection_reason_type: this.rejectionReasonType,
              type: "rejection",
            },
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.showSuccess("Booking successfully rejected.");
              this.$emit("refresh");
              this.getBookingDetails();
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
  },
};
</script>

<style scoped lang="scss">

.products-table {
  max-height: 300px;
  overflow-y: auto;
  display: block;
}

.products-table-body {
  max-height: 300px;
  overflow-y: auto;
}

.details_title {
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(0, 89, 118) !important;
}

.details_subtitle {
  font-size: 13px;
  color: #8b8b8b;
  text-align: left;
  font-weight: 400;
}

.details_data {
  font-size: 16px;
  padding: 3px 0;
  text-align: left;
  font-weight: 600;
}

.attended {
  background: grey !important;
  color: white !important;
  cursor: not-allowed;
}
</style>
