<template>
  <div class="mt-3">
    <div
        class="bg-white bordered rounded-lg pb-8 mt-8"
    >
        <div class="d-flex justify-space-between align-center bg-neon opacity-10 border-bottom pa-4">
          <div class="title-text">Repeat booking occurrences</div>
        </div>
        <v-row class="ma-3">
          <v-col md="2">
            <v-row>
              <v-list-item-action class="mr-1">
                <v-checkbox
                  @change="selectAll()"
                  v-model="select_all"
                  :readonly="openProduct"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>All</v-list-item-title>
              </v-list-item-content>
            </v-row>
          </v-col>
          <v-col
            md="2"
            v-for="(date, index) in repeat_dates_for_payment"
            :key="index"
          >
            <v-row>
              <v-list-item-action class="mr-1">
                <v-checkbox
                  :disabled="date.status_id == 2"
                  :indeterminate="date.status_id == 2"
                  :readonly="openProduct"
                  @change="isPaidCheckBoxEnable()"
                  v-model="date.isPaid"
                  :color="date.status_id == 1 ? 'success' : 'primary'"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content
                v-bind:class="{
                  paid_checkBox: date.status_id == 1,
                  'text-decoration-line-through': date.status_id == 2,
                }"
              >
                <v-list-item-title>{{
                  date.date | calendarYearFormat
                }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{ date.start_time | timeFormat }} to
                  {{ date.end_time | timeFormat }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-row>
          </v-col>
        </v-row>
    </div>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    >
    </confirm-model>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    facility_id:{ type: Number, default: null },
    repeatId: { type: Number, default: null },
    show: { type: Boolean, default: false },
    openProduct: { type: Boolean, default: false },
    date: { type: String, default: null },
    start_time: { type: String, default: null },
    end_time: { type: String, default: null },
  },
  data() {
    return {
      repeat_dates_for_payment: [],
      orderId: null,
      repeatDialog: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      select_all: false,
    };
  },
  computed: {},
  watch: {
    repeatId: {
      immediate: true,
      handler(val) {
        this.repeat_dates_for_payment = [];
        if (val > 0) {
          this.getRepeatBookings();
        }
      },
    },
    openProduct: {
      immediate: true,
      handler(val) {
        if (val) this.getRepeatBookings();
      },
    },
  },
  methods: {
    confirmMultiPayment() {
      if (
        this.repeat_dates_for_payment.filter((element) => element.isPaid)
          .length == 0
      ) {
        this.showError("Please select date !");
        return;
      }
      this.confirmModel = {
        id: 1,
        description: `Do you want to separate invoice from other repeat dates? By clicking <b>Yes</b> you can confirm creating new invoice`,
        type: "pay",
      };
    },

    confirmActions() {
      this.pay();
      this.confirmModel.id = null;
    },

    reschedule() {
      let booking = this.repeat_dates_for_payment.find((item) => item.isPaid);
      this.$emit("reschedule", booking.booking_id);
    },

    isPaidCheckBoxEnable() {
      if (this.openProduct) return;
      const dates = this.repeat_dates_for_payment;
      if (dates.filter((element) => element.isPaid).length > 0) {
        var formData = new FormData();
        var isPaidIndex = 0;
        dates.forEach((facilityBookings) => {
          if (facilityBookings.isPaid) {
            formData.append(
              `dates[${isPaidIndex}][date]`,
              facilityBookings.date
            );
            formData.append(
              `dates[${isPaidIndex}][order_id]`,
              facilityBookings.order_id
            );
            formData.append(
              `dates[${isPaidIndex}][facility_id]`,
              facilityBookings.facility_id
            );
            formData.append(
              `dates[${isPaidIndex}][start_time]`,
              facilityBookings.start_time
            );
            formData.append(
              `dates[${isPaidIndex}][end_time]`,
              facilityBookings.end_time
            );
            formData.append(
              `dates[${isPaidIndex}][booking_id]`,
              facilityBookings.booking_id
            );
            isPaidIndex++;
          }
        });

        // for (var pair of formData.entries()) {
        // }
        this.showLoader();
        this.$http
          .post(`venues/facilities/bookings/multiple-dates-rentals`, formData)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status == true) {
              if (response.data.data) {
                this.$emit(
                  "repeatDatesForPayment",
                  this.repeat_dates_for_payment,
                  response.data.data
                );
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      } else if (dates.filter((element) => element.isPaid).length == 0) {
        this.$emit(
          "repeatDatesForPayment",
          this.repeat_dates_for_payment,
          null
        );
      }
    },

    selectAll() {
      this.repeat_dates_for_payment.forEach((element) => {
        if (element.status_id != 2) {
          element.isPaid = this.select_all;
        }
      });
      this.isPaidCheckBoxEnable();
      this.$forceUpdate();
    },

    getRepeatBookings() {
      this.showLoader();
      this.$http
        .get(
          `venues/facilities/bookings/repeat-bookings-by-repeat-id/` +
            this.repeatId
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.repeat_dates_for_payment = response.data.data;
            this.repeat_dates_for_payment.forEach((booking) => {
              if (
                booking.date == this.date &&
                booking.start_time == this.start_time &&
                booking.end_time == this.end_time &&
                booking.facility_id === this.facility_id
              ) {
                booking.isPaid = true;
              } else if (this.openProduct) {
                booking.isPaid = true;
              }
            });
            this.isPaidCheckBoxEnable();
            if (this.openProduct) {
              this.select_all = true;
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style>
.paid_checkBox {
  color: #4caf50 !important;
  font-weight: 600;
  cursor: pointer;
}
</style>